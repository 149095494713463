import React, { useContext, useEffect, useState } from 'react'
import Navbar from './Navbar';
import Chats from './Chats';
import Search from './Search';
import More from '../assets/icon/more.png';
import { ChatContext } from '../context/ChatContext';

const Sidebar = () => {
  const [expand, setExpand] = useState(false);
  const { data, dispatch } = useContext(ChatContext) as any;

  useEffect(() => {
    if (data?.chatId || data?.chatId === 'null') {
      setExpand(true);
    } else {
      setExpand(false);
    }
  }, [data])

  return (
    <div className={`sidebar ${expand ? 'expandMenu' : ''}`}>
      <Navbar />
      <div className={`${(data?.chatId) ? 'hide' : 'show'}`}>
        <Search />
        <Chats />
      </div>
      {!!expand && <div className="more" >
        <img src={More} onClick={() => {
          dispatch({ type: 'RESET_USER', payload: [] });
          setExpand(pv => !pv)
        }} />
      </div>}
    </div>
  )
}

export default Sidebar 