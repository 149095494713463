import React, { useContext } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase/firebase';
import { AuthContext } from '../context/AuthContext';

const Navbar = () => {
  const { currentUser } = useContext(AuthContext)
  return (
    <div className='navbar'>
      <span className='logo'> <img src={require('../assets/p-chatlogo.png')} width={'auto'} height={'50px'}></img>
      </span>
      <div className='user'>
        <img src={currentUser?.photoURL} alt='photoURL' id='photoURL' />
        <span>{currentUser?.displayName}</span>
        <button onClick={() => signOut(auth)}>logout</button>
      </div>
    </div>
  )
}

export default Navbar