import { createContext, useEffect, useState } from 'react';
import { auth } from '../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';

interface IUserContext {
  displayName: string;
  photoURL: string;
  uid: string;
  email: string
}
const defaultUser = {
  displayName: '',
  photoURL: '',
  uid: '',
  email: ''
};

export const AuthContext = createContext({currentUser: defaultUser});

export const AuthContextProvider: React.FC<any> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<IUserContext>(defaultUser);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user: any) => {
      setCurrentUser(user);
    });

    return () => {
      unsub();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};