import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import customClass from './login.module.scss'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase/firebase';

const Login = () => {
  const [err, setErr] = useState(false);
  const [rememberMe, setRemember] = useState(false);
  const emailRef = useRef() as any;
  const passworfRef = useRef() as any;
  const rememberRef = useRef() as any;
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('rememberMe')) {
      const email = localStorage.getItem('userEmail');
      const userPassword = localStorage.getItem('userPassword');
      setRemember(true);
      emailRef.current.value = email;
      passworfRef.current.value = userPassword;
    }
  }, [])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const email = emailRef?.current?.value;
    const password = passworfRef?.current?.value;
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if (rememberRef?.current?.checked) {
        localStorage.setItem('rememberMe', '1');
        localStorage.setItem('userEmail', email);
        localStorage.setItem('userPassword', password);
      } else {
        localStorage.clear();
      }
      navigate("/")
    } catch (err: any) {
      setErr(true);
      if (err['message'] === 'Firebase: Error (auth/user-not-found).') {
        alert('User not available');
      }
      if (err['message'] === 'Firebase: Error (auth/invalid-email).' || err['message'] === 'Firebase: Error (auth/wrong-password).') {
        alert('Invalid User');
      }
    }
  };


  return (
    <div className={customClass.formContainer}>
      <div className={customClass.formWrapper}>
        <span className={customClass.logo}>
          <img src={require('../../assets/p-chatlogo.png')} width={'auto'} height={'160px'}></img>
        </span>
        <span className={customClass.title}>Login</span>
        <div className={customClass.form}>
          <input name="email" required ref={emailRef} autoComplete='off' type="email" placeholder="Email" />
          <input name="password" onKeyDown={(event) => {
            if (event.key === 'Enter') handleSubmit(event)
          }}
            ref={passworfRef} autoComplete='off' type="password" placeholder="Password" />
          <button type='button' onClick={handleSubmit}>Sign in</button>
          {err && <span style={{ color: 'red' }}>Something went wrong</span>}
        </div>
        <p>You don't have an account? <Link to="/register">Register</Link></p>
        <label>
          <input type="checkbox" ref={rememberRef} checked={rememberMe} onChange={() => setRemember(pv => !pv)} name="remember" /> Remember me
        </label>
      </div>
    </div>
  )
}

export default Login