import React, { useContext, useEffect, useState } from 'react';
import Img from '../assets/icon/img.png';
import Attach from '../assets/icon/attach.png';
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from '../context/ChatContext';
import {
  arrayUnion,
  doc,
  onSnapshot,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { db, storage } from '../firebase/firebase';
import { v4 as uuid } from 'uuid';
import { getDownloadURL, getStorage, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';

const Input = () => {
  const [text, setText] = useState('');
  const [file, setFile] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext<any>(ChatContext);

  const handleSend = async (e: any) => {
    e.preventDefault()
    if (!text && !file) {
      return
    }

    setLoading(true);
    if (file) {
      const storage = getStorage();
      const storageRef = ref(storage, uuid());
      const uploadTask = uploadBytesResumable(storageRef, file);
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          // Handle unsuccessful uploads
          setText('');
          setFile(null);
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            console.log('File available at', downloadURL);
            await updateDoc(doc(db, 'chats', data?.chatId), {
              messages: arrayUnion({
                id: uuid(),
                text,
                senderId: currentUser?.uid,
                date: Timestamp.now(),
                fileType: file?.type ?? '',
                fileName: file?.name,
                file: downloadURL,
              }),
            });
          });
        }
      );

      // const storageRef = ref(storage, uuid()); 
      // const uploadTask = uploadBytesResumable(storageRef, file);
      // uploadTask.on('state_changed',
      //   (snapshot) => {
      //     try {
      //       let percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      //       setProgress(percent);  
      //       getDownloadURL(snapshot?.ref).then(async (downloadURL) => {
      //         console.log('downloadURL', downloadURL);
      //         await updateDoc(doc(db, 'chats', data?.chatId), {
      //           messages: arrayUnion({
      //             id: uuid(),
      //             text,
      //             senderId: currentUser?.uid,
      //             date: Timestamp.now(),
      //             fileType: file?.type ?? '',
      //             fileName: file?.name,
      //             file: downloadURL,
      //           }),
      //         });
      //       });
      //     } catch (err) {
      //       setText('');
      //       setFile(null);
      //       console.log('err', err)
      //     }
      //   }
      // );
    } else {
      await updateDoc(doc(db, 'chats', data?.chatId), {
        messages: arrayUnion({
          id: uuid(),
          text,
          senderId: currentUser?.uid,
          date: Timestamp.now(),
        }),
      });
    }
    const messageLength = () => {
      return new Promise ((resolve)=> onSnapshot(doc(db, 'chats', data?.chatId ?? ''), (doc: any)=> resolve(doc?.data().messages?.length)))
    }
    const count = await messageLength() ?? 0;
    await updateDoc(doc(db, 'userChats', currentUser?.uid), {
      [data?.chatId + '.lastMessage']: {
        text,
      },
      [data?.chatId + '.messageLength']: {
        count,
      },
      [data?.chatId + '.date']: serverTimestamp(),
    });

    await updateDoc(doc(db, 'userChats', data?.user?.uid), {
      [data?.chatId + '.lastMessage']: {
        text,
      },
      [data?.chatId + '.messageLength']: {
        count,
      },
      [data?.chatId + '.date']: serverTimestamp(),
    });

    setText('');
    setFile(null);
    setLoading(false);
  }; 

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      position: 'relative'
    }}>
      {progress > 0 && progress < 100 && <span style={{
        height: '8px',
        background: 'green',
        width: `${progress}%`
      }}></span>}
      {!!file && <h6 style={{
        color: 'white',
        margin: 0,
        padding: '0.5rem 1rem',
        position: 'absolute',
        bottom: '68px',
        background: '#2f2d52',
        width: '100%'
      }}>{
          file.name
        }</h6>}
      <div className='input'>
        <input
          type='text'
          placeholder='Type something...'
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => { if (e.key === 'Enter') { handleSend(e) } }}
          value={text}
        />
        <div className='send'>
          <input
            type='file'
            style={{ display: 'none' }}
            id='file'
            onChange={(e: any) => setFile(e?.target?.files[0])}
          />
          <label htmlFor='file'>
            <img src={Attach} alt='' />
          </label>
          <button style={{ borderRadius: '10px' }} 
          disabled={loading} 
          onClick={handleSend}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Input;
