import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.scss';
import Login from './pages/login/login';
import Register from './pages/register/register';
import Home from './pages/home/home';
import { AuthContext } from "./context/AuthContext";
import { ChatContext } from './context/ChatContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from './firebase/firebase';

const ProtectedRoute: React.FC<any> = ({ children, currentUser }) => {
  if (!currentUser?.email) {
    return <Navigate to="/login" />;
  }

  return children
};

const App = () => {
  const { currentUser } = useContext(AuthContext);
  /*  
    useEffect(() => {
       const getChats = () => {
        const unsub = onSnapshot(doc(db, 'userChats', currentUser?.uid), (doc) => {
          // do what you need
         if (Object.values(doc?.data() ?? {}).length) {
            Object.values(doc?.data() ?? {})?.forEach((element: any) => {
              if (element) {
                const txt = `messageLength-${element?.userInfo?.displayName}`;
                const count = element?.messageLength?.count?.toString();
                if (localStorage.getItem(txt) && count && localStorage.getItem(txt) !== count) {
                  localStorage.setItem(txt, count);
                  if (document.hidden) {
                    const notification = new Notification(`PChat - ${element?.userInfo?.displayName}`, {
                      body: 'New Message',
                    });
                    notification.onclick = (event) => {
                      event.preventDefault();
                      window.open('https://chat.creativebonding.in/', '_blank');
                    }
                  }
                } else {
                  localStorage.setItem(txt, count ?? '');
                }
              }
            });
          } 
        });
  
        return () => {
          unsub();
        };
        
      };
  
      currentUser?.uid && getChats(); 
    }, [currentUser?.uid]);
  
    useEffect(() => {
        Notification.requestPermission(function (result) {
          if (result === 'granted') {
            navigator.serviceWorker.ready.then(function (registration) {
              registration.showNotification('Notification with ServiceWorker');
            });
          }
        });
      }, []) */

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <ProtectedRoute currentUser={currentUser}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
