import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import customClass from './register.module.scss'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { auth, db, storage } from '../../firebase/firebase';
import { doc, setDoc } from 'firebase/firestore';
import Add from '../../assets/icon/add.png';

const Register = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const displayNameRef = useRef() as any;
  const emailRef = useRef() as any;
  const passwordRef = useRef() as any;
  const [fileRef, setFileRef] = useState<any>(null);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const displayName = displayNameRef?.current?.value;
    const email = emailRef?.current?.value;
    const password = passwordRef?.current?.value; 
    if(!fileRef) {
      alert('Please upload profile pic')
      return;
    }
    setLoading(true);
    try {
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);

      //Create a unique image name
      const date = new Date().getTime();
      const storageRef = ref(storage, `${displayName + date}`);

      await uploadBytesResumable(storageRef, fileRef).then(() => {
        getDownloadURL(storageRef).then(async (downloadURL) => {
          try {
            //Update profile
            await updateProfile(res.user, {
              displayName,
              photoURL: downloadURL,
            });
            //create user on firestore
            await setDoc(doc(db, "users", res.user.uid), {
              uid: res.user.uid,
              displayName,
              email,
              photoURL: downloadURL,
            });

            //create empty user chats on firestore
            await setDoc(doc(db, "userChats", res.user.uid), {});
            navigate("/");
          } catch (err) {
            setErr(true);
            setLoading(false);
          }
        });
      });
    } catch (err) {
      setErr(true);
      setLoading(false);
    }
  }

  return (
    <div className={customClass.formContainer}>
      <div className={customClass.formWrapper}>
        <span className={customClass.logo}><img src={require('../../assets/p-chatlogo.png')} width={'auto'} height={'100px'}></img></span>
        <span className={customClass.title}>Register</span>
        <div className={customClass.form} >
          <input required ref={displayNameRef} name='displayName' autoComplete='off' type="text" placeholder="User name" />
          <input required ref={emailRef} name='email' autoComplete='off' type="email" placeholder="Email" />
          <input required ref={passwordRef} name='password' autoComplete='off' type="password" placeholder="Password" />
          <input required onChange={(e: any) => {
            setFileRef(e?.target?.files[0]);
          }} name='file' style={{ display: "none" }} type="file" id="file" />
          <label htmlFor="file">
            <img src={Add} alt="" />
            <span>Add an avatar</span>
          </label>
          {!!fileRef?.name && <h6 style={{margin:0}}>Profile: {fileRef?.name}</h6>}
          <button onClick={handleSubmit} disabled={loading}>{loading ? 'creating...' : 'Sign up'}</button>
          {loading && "Uploading and compressing the image please wait..."}
          {err && <span>Something went wrong</span>}
        </div>
        <p>
          You do have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  )
}

export default Register