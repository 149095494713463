import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import Download from '../assets/icon/download.svg';

const Message: React.FC<any> = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext) as any;

  const getAttachmentPreview = (message: any) => {
    if (message.fileType.includes('image')) {
      return <img src={message?.file} style={{ width: '100px' }} alt="" />
    } else if (message.fileType.includes('audio')) {
      return <audio style={{ width: '240px' }} src={message.file} controls />
    } else if (message.fileType.includes('video')) {
      return <video src={message.file} controls style={{ width: '240px' }} />
    }
  }

  const getTextType = (text: string) => {
    if (text?.includes('http')) {
      let link = text;
      if (text?.includes('instagram') && text?.includes('reel/')) {
        const linkId = link.split('reel/')
        link = `instagram://media?id=${linkId[1]}`
      }
      return <p style={{ margin: 0 }}><a style={{
        maxWidth: '200px',
        wordBreak: 'break-all',
        color: message?.senderId === currentUser?.uid ? 'white' : 'black'
      }} href={link} target="_blank">{text}</a></p>
    } else {
      return <p style={{ margin: 0 }}>{text}</p>
    }
  }

  return (
    <div
      className={`message ${message?.senderId === currentUser?.uid && "owner"}`}
    >
      <div className="messageInfo">
        <img
          src={
            message?.senderId === currentUser?.uid
              ? currentUser?.photoURL
              : data?.user?.photoURL
          }
          alt=""
        />
      </div>
      <div className="messageContent">
        {!message?.file && getTextType(message?.text)}
        {message?.file && <p style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'min-content',
          alignItems: 'center',
          margin: 0
        }}>
          {getAttachmentPreview(message)}
          {!!(message?.senderId !== currentUser?.uid) && <a style={{
            color: 'black',
            boxShadow: '0px 0px 8px 2px #b8b8b88f',
            justifyContent: 'center',
            display: 'flex',
            flexShrink: 0,
            marginTop: '7px',
            background: ' white',
            borderRadius: '50%',
            padding: '5px'
          }} target="_blank" href={message.file} download={`${message?.fileName}`}>
            <img src={Download} style={{ width: '20px' }} alt="download=icon" />
          </a>}
        </p>}
        <span style={{ color: 'black', fontSize: 'xx-small' }}>{new Date(message?.date?.seconds * 1000).toLocaleString()}</span>
      </div>
    </div >
  );
};

export default Message;