import { Firestore, deleteField, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ChatContext } from '../context/ChatContext';
import { db } from '../firebase/firebase';
import Message from './Message';
import './common.scss';

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const { data } = useContext<any>(ChatContext);
  const ref = useRef() as any;

  useEffect(() => {
    const unSub = data?.chatId ? onSnapshot(doc(db, 'chats', data?.chatId ?? ''), (doc: any) => {
      doc?.exists() && setMessages(doc?.data()?.messages);
    }) : () => { };

    return () => {
      unSub();
    };
  }, [data?.chatId]);

  useEffect(() => {
    setTimeout(() => {
      const scrollHeight = ref.current.scrollHeight;
      ref.current.scrollTop = scrollHeight;
      // ref.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 400)
  }, [messages]);

  const deleteCollection = async () => {
    if (window.confirm('click OK to delete chat?') === true) {
      const dbRef = doc(db, 'chats', data?.chatId ?? '');
      await updateDoc(dbRef, {
        messages: deleteField()
      });
    }
  }

  return (
    <div className='messages' ref={ref}>
      {!!messages?.length && data?.chatId && <button className='delete-collection' onClick={deleteCollection}>delete</button>}
      {messages?.map((m: any, index) => (
        <Message message={m} key={index} />
      ))}
    </div>
  );
};

export default Messages;